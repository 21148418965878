.invoice-page {
  width: 100%;
  /* padding-bottom: 70px; */
  padding-bottom: 60px;
}

.toaster {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: red;
  padding-right: 90px;
  padding-left: 90px;
}

.toaster p {
  padding: 10px;
  font-style: italic;
  text-align: center;
  line-height: 22px;
}

.toaster p a {
  color: red;
}

.invoice-page .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #00000010;
}

.invoice-page .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
}

.invoice-page .top .right {
  display: flex;
  align-items: center;
}

.invoice-page .top .add-icon {
  mask-image: url("../../assets/icons/plus-circle.svg");
  width: 36px;
  height: 36px;
  background-color: #036323;
  mask-position: center;
  mask-repeat: no-repeat;
  margin-left: 30px;
  cursor: pointer;
}

.invoice-page .btn-wrap button {
  width: 220px;
  height: 46px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.4;
  transition: 0.3s;
}

.invoice-page .btn-wrap button.arch-btn {
  background: #0000005b;
  opacity: 1;
  cursor: pointer;
  margin-right: 12px;
}

.invoice-page .btn-wrap button.button-active {
  opacity: 1;
  cursor: pointer;
}

.no-invoice {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f9f9f9;
  min-height: calc(100vh - 228px);
  width: 100%;
  display: grid;
  place-content: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.invoice-add-card {
  width: 540px;
  height: 306px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(0, 0, 0, 0.6)' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.invoice-add-card .center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.invoice-add-card .center .img-wrap {
  margin-bottom: 27px;
}

/* ---------- invoice cards -------------- */

.invoice-cards {
  padding: 46px 90px;
  padding-bottom: 150px;
}

.invoice-cards .cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 90px;
  row-gap: 90px;
  flex-wrap: wrap;
}

.cards-wrapper a {
  text-decoration: none;
  color: #000000;
}

.invoice-cards .cards-wrapper .invoice-card {
  width: 409px;
  height: 232px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  position: relative;
}

.cards-wrapper .invoice-card .card-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
  padding: 24px;
  border-radius: 10px;
  /* z-index: 100; */
}

.cards-wrapper a:nth-child(2) .card-wrapper {
  background: linear-gradient(90deg, #43e97b 0%, #38f9d7 100%);
}

.cards-wrapper a:nth-child(3) .card-wrapper {
  background: linear-gradient(90deg, #9be15d 0%, #00e3ae 100%);
}

.cards-wrapper a:nth-child(4) .card-wrapper {
  background: linear-gradient(90deg, #43e97b 0%, #38f9d7 100%);
}

.cards-wrapper a:nth-child(5) .card-wrapper {
  background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
}

.cards-wrapper a:nth-child(6) .card-wrapper {
  background: linear-gradient(90deg, #9be15d 0%, #00e3ae 100%);
}

.cards-wrapper a:nth-child(7) .card-wrapper {
  background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
}

.cards-wrapper .invoice-card .on-hover-card {
  display: none;
}

/* .invoice-cards .cards-wrapper .card-wrapper:hover {
  filter: brightness(0.3);
}

.invoice-cards .cards-wrapper .card-wrapper:hover + .on-hover-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  place-content: center;
  pointer-events: none;
} */

.on-hover-card img {
  margin-right: auto;
  margin-left: auto;
}

.on-hover-card p {
  font-size: 26px;
  color: #ffffff;
  margin-top: 10px;
}

.cards-wrapper .invoice-card .invoice-name {
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-right: 10px;
}

.cards-wrapper .invoice-card .card-amount {
  text-align: center;
}

.invoice-card .card-amount > span:first-child {
  font-size: 26px;
  margin-right: 4px;
}

.cards-wrapper .invoice-card .card-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.invoice-card .card-bottom .invoice-num,
.invoice-card .card-bottom .invoice-date {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.02em;
}

.invoice-card .card-bottom .invoice-num span:first-child,
.invoice-card .card-bottom .invoice-date span:first-child {
  color: #000000;
  opacity: 0.6;
}

.ant-spin-text {
  color: #ffffff;
}

span.mobile {
  display: none;
}

a.mobile {
  display: none;
}

@media (max-width: 768px) {
  .invoice-page .top h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .invoice-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .invoice-add-card {
    width: 280px;
    height: 156px;
  }
  .invoice-add-card p {
    font-size: 14px;
  }

  .invoice-page .btn-wrap button {
    width: 200px;
    height: 36px;
    font-size: 14px;
  }

  .invoice-page .right > div {
    width: 100%;
    justify-content: space-between;
  }

  .invoice-page .btn-wrap {
    width: 49%;
  }

  .invoice-page .btn-wrap button {
    width: 100%;
  }

  .invoice-page .top .right {
    width: 100%;
    justify-content: space-between;
  }

  .invoice-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

@media (max-width: 609px) {
  .invoice-cards {
    padding: 46px 0px;
    padding-bottom: 70px;
  }

  .invoice-cards .cards-wrapper {
    column-gap: 0;
    row-gap: 50px;
    justify-content: center;
  }

  .toaster {
    padding-right: 0;
    padding-left: 0;
  }

  .toaster p {
    font-size: 12px;
    line-height: 15px;
  }

  span.mobile {
    display: inline;
    /* pointer-events: auto; */
  }

  /* .cards-wrapper .invoice-card {
    z-index: 10000;
  } */
  a.mobile {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 576px) {
  .invoice-page .top .right > div {
    flex-direction: column;
    align-items: flex-end;
  }

  .invoice-page .btn-wrap {
    width: 100%;
  }
  .invoice-page .btn-wrap button.arch-btn {
    margin-bottom: 12px;
  }
}

@media (max-width: 530px) {
  .invoice-cards .cards-wrapper .invoice-card {
    width: 350px;
  }

  .invoice-cards {
    padding-bottom: 90px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .invoice-cards .cards-wrapper .card-wrapper:hover {
    filter: brightness(0.3);
  }

  .invoice-cards .cards-wrapper .card-wrapper:hover + .on-hover-card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-content: center;
    pointer-events: none;
    z-index: 100;
  }
}
