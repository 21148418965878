.rate-btn {
  padding: 10px;
  background-color: #ffc300;
  position: fixed;
  bottom: 30px;
  right: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  -webkit-animation: rateShadow 1.3s infinite;
  -moz-animation: rateShadow 1.3s infinite;
  -o-animation: rateShadow 1.3s infinite;
  animation: rateShadow 1.3s infinite;
}

.rate-btn img {
  width: 30px;
  transform: rotate(45deg);
  animation: antRotate 1.5s infinite linear;

  /* animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: antRotate;
  animation: antRotate 1.2s infinite; */
}

@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}

@keyframes rateShadow {
  0%,
  100% {
    box-shadow: 1px 0px 19px 4px #ffae00;
    /* inset 0px 0px 10px rgba(255, 255, 255, 0.5); */
  }

  /* 50% {
    box-shadow: 0px 0px 0px 0px rgba(255, 245, 3, 0),
      inset 0px 0px 0px rgba(255, 255, 255, 0);
  } */
  50% {
    box-shadow: 1px 0px 15px 4px #f1d596;
    /* inset 0px 0px 0px rgba(255, 255, 255, 0); */
  }
}
