.roow input[type="file"] {
  overflow: hidden;
  cursor: pointer;
  padding: 0;
  /* position: relative; */
}

input[type="file"]::file-selector-button {
  border: none;
  padding: 12px;
  border-radius: 0.2em;
  transition: 0.3s;
  height: 100%;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

input[type="file"]::file-selector-button:hover {
  background-color: rgb(224, 224, 224);
}

.add-fcc-modal .multi-select div input {
  height: auto;
  font-family: "Monsterrat";
}

.add-fcc-modal .multi-select > div:nth-child(3) {
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  border-color: #00000010 !important;
  box-shadow: none;
}

.add-fcc-modal .multi-select > div:hover {
  border-color: #00000010;
}

.add-fcc-modal .roow > div.row-full {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.drug-list {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  font-family: "Montserrat";
  letter-spacing: 0.02em;
  margin-top: 15px;
}

.drug-list .drug-info {
  display: flex;
  flex-direction: column;
  justify-self: start;
}
.drug-list .drug-info .drug-label {
  font-size: 16px;
  margin-bottom: 14px;
  opacity: 0.8;
}
.drug-list .drug-info .drug-name {
  font-size: 20px;
  opacity: 0.8;
  font-weight: 500;
}

.drug-list .added-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 230px;
  width: 100%;
  /* justify-self: end; */
  /* align-self: flex-end; */
}
.drug-list .drug-checked {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.drug-list .drug-checked span {
  margin-left: 15px;
  color: #036323;
  font-size: 16px;
}

.drug-list .drug-edit {
  display: flex;
  align-content: center;
  justify-content: center;
}

.add-fcc-modal .btn-wrap .add-btn {
  background: #2a7fff;
  margin-right: 10px;
}

/* select */

.inner-fcc-page .top .select-wrap {
  width: 460px;
  height: 46px;
  border: 2px solid #00000015;
  border-radius: 6px;
  position: relative;
  margin-left: 15px;
}

.inner-fcc-page .top .select-wrap select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 12px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/select.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.add-fcc-modal .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
}

.add-fcc-modal .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #036323;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.add-fcc-modal .roow .row-dyn {
  display: flex;
  width: auto;
  flex-direction: column;
}

.add-fcc-modal .roow.align-center {
  justify-content: center;
}

.file-link {
  font-size: 16px;
  font-family: "Montserrat";
}

.file-link:hover {
  text-decoration: underline;
}

span.pending {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.balance {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
}

@media (max-width: 1200px) {
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
  }
  .inner-fcc-page .top .select-wrap {
    width: 220px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .inner-fcc-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .inner-fcc-page .top .right {
    width: 100%;
    justify-content: space-between;
  }

  .inner-fcc-page .top .select-wrap {
    width: 49%;
  }
}

@media (max-width: 576px) {
  .inner-fcc-page .top .right {
    flex-direction: column;
    align-items: flex-end;
  }
  .inner-fcc-page .top .select-wrap {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 12px;
  }
}
