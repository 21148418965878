.select-login {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #d3d3d3;
  display: grid;
  place-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.select-login .wrapper-card {
  width: 600px;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 25px;
}

.select-login .wrapper-card .card-content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.select-login .logo-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-login .logo-content img {
  height: 36px;
}

.select-login .logo-content p {
  text-align: left;
  margin-left: 8px;
  line-height: 22px;
  margin-bottom: 0;
}

.select-login label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.select-login label input {
  position: absolute;
  left: -9999px;
}

.select-login label input:checked + span {
  background-color: #335f42;
  color: white;
}

.select-login label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #036323;
}

.select-login label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 8px;
  transition: 0.25s ease;
  color: #5f9671;
}

.select-login label span:hover {
  background-color: #d6d6e5;
}

.select-login label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #005f20;
}

.select-login .btn-wrapper {
  display: flex;
  justify-content: end;
}

.select-login .btn-wrapper .login-btn {
  padding: 9px 12px;
  border-radius: 8px;
  color: #fff;
  background-color: #036323;
  border: none;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.4;
}

.select-login .btn-wrapper .login-btn.button-active {
  cursor: pointer;
  opacity: 1;
}

.select-login .roow {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.select-login .roow > div {
  display: flex;
  width: 48.5%;
  flex-direction: column;
  justify-content: end;
}

.select-login label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 5px;
}

.select-login .react-custom-select > div:nth-child(3) {
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  border-color: #00000010 !important;
  box-shadow: none;
}

.select-login .react-custom-select > div:nth-child(3) > div {
  height: 46px;
}

.select-login .roow input {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}
.select-login .roow textarea {
  width: 100%;
  /* height: 46px; */
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}

.select-login .roow select {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/select.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.select-login .roow > div.row-full {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.select-login .btn-wrapper {
  display: flex;
  justify-content: end;
}

.select-login .btn-wrapper .login-btn {
  padding: 9px 12px;
  border-radius: 8px;
  color: #fff;
  background-color: #036323;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.select-login .langs-outer {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media (max-width: 768px) {
  .select-login .wrapper-card {
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 25px;
  }

  .select-login .roow {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .select-login .roow > div {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .select-login label {
    font-size: 14px;
  }
  .select-login .roow input {
    height: 36px;
    font-size: 14px;
    padding: 5px;
  }
  .select-login .roow select {
    height: 36px;
    padding: 5px;
  }
  .select-login .btn-wrap button {
    width: 200px;
    height: 36px;
    font-size: 14px;
  }
  .select-login .about-product .left {
    width: calc(100% - 50px);
  }
  .select-login .about-product .left input {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .select-login .about-product .left select {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .select-loginl .about-product .right-btn {
    width: 36px;
    height: 36px;
  }
  .select-login .about-product .right-btn .icon {
    font-size: 18px;
  }

  .select-login .about-product .left input.name-input {
    width: 32%;
  }
}
