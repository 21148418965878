.select-login.login .roow {
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.select-login.login .roow > div {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 15px;
}

.sign-link {
  color: black;
  /* text-decoration: none; */
}

.sign-link:hover {
  color: #036323;
}

button.button-active {
  cursor: pointer;
  opacity: 1;
}

.select-login .btn-wrapper {
  display: flex;
  justify-content: end;
}

.select-login .btn-wrapper .login-btn {
  padding: 9px 12px;
  border-radius: 8px;
  color: #fff;
  background-color: #036323;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.select-login .langs-outer {
  display: flex;
  justify-content: end;
  align-items: center;
}
