.add-fcc-modal {
  overflow: hidden;
}
.add-fcc-modal h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
}

.add-fcc-modal h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
}

.add-fcc-modal ul > li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 4px;
}

.add-fcc-modal .detail-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .icon {
  color: #036323;
  font-size: 22px;
}

.add-fcc-modal .roow {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.add-fcc-modal .roow.row-line {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 32px;
  margin-top: 32px;
}

.add-fcc-modal .roow > div {
  display: flex;
  width: 48.5%;
  flex-direction: column;
  justify-content: end;
}

.add-fcc-modal label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 5px;
}

.add-fcc-modal .roow input {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}
.add-fcc-modal .roow textarea {
  width: 100%;
  /* height: 46px; */
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}

.add-fcc-modal .roow select {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/select.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.add-fcc-modal .btn-wrap {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.add-fcc-modal .btn-wrap button {
  width: 220px;
  height: 46px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.4;
  transition: 0.3s;
}

.add-fcc-modal .btn-wrap button.button-active {
  cursor: pointer;
  opacity: 1;
}

.add-fcc-modal .about-product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.add-fcc-modal .about-product .left {
  width: calc(100% - 70px);
  display: flex;
  justify-content: space-between;
}

.add-fcc-modal .about-product .left input {
  width: 24%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  padding: 10px;
}

.add-fcc-modal .about-product .left input.name-input {
  width: 48%;
}

.add-fcc-modal .about-product .left select {
  width: 24%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  padding: 10px;
}

.add-fcc-modal .about-product .right-btn {
  width: 46px;
  height: 46px;
  display: grid;
  place-content: center;
  background-color: #d71b1b;
  border-radius: 6px;
  cursor: pointer;
}

.add-fcc-modal .about-product .right-btn .icon {
  font-size: 24px;
  color: #ffffff;
}

.add-fcc-modal .about-product1 .right-btn {
  background-color: #036323;
}

.add-fcc-modal .table-wrap {
  width: 100%;
  overflow-x: auto;
}

.add-fcc-modal .table-wrap table {
  width: 100%;
}

.add-fcc-modal .table-wrap table tr {
  border-bottom: 2px solid #00000010;
  transition: 0.3s;
}

.add-fcc-modal .table-wrap table th {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .table-wrap table td {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .table-wrap table tbody tr:nth-child(2n + 2) {
  background-color: #f6f6f6;
}

.add-fcc-modal .table-wrap table tbody tr:hover {
  background-color: #f6f6f6;
}

@media (max-width: 768px) {
  .add-fcc-modal .roow {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .add-fcc-modal .roow > div {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .add-fcc-modal label {
    font-size: 14px;
  }
  .add-fcc-modal .roow input {
    height: 36px;
    font-size: 14px;
    padding: 5px;
  }
  .add-fcc-modal .roow select {
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .btn-wrap button {
    width: 200px;
    height: 36px;
    font-size: 14px;
  }
  .add-fcc-modal .about-product .left {
    width: calc(100% - 50px);
  }
  .add-fcc-modal .about-product .left input {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .about-product .left select {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .about-product .right-btn {
    width: 36px;
    height: 36px;
  }
  .add-fcc-modal .about-product .right-btn .icon {
    font-size: 18px;
  }

  .add-fcc-modal .about-product .left input.name-input {
    width: 32%;
  }
}
