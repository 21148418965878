.inner-fcc-page {
  width: 100%;
  padding-bottom: 70px;
}

.inner-fcc-page .top {
  min-height: 88px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #00000010;
}

.inner-fcc-page .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
}

.inner-fcc-page .top .right {
  display: flex;
  align-items: center;
}

.inner-fcc-page .top .input-wrap {
  width: 460px;
  height: 46px;
  border: 2px solid #00000015;
  border-radius: 6px;
  position: relative;
}

.inner-fcc-page .top .input-wrap > div {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  mask-image: url("../../assets/icons/search\ 3.svg");
  background-color: #00000060;
}

.inner-fcc-page .top .input-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 56px;
  outline: none;
}

.inner-fcc-page .top .icoon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #00000060;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.inner-fcc-page .top .icoon.icoon-visible {
  display: block;
}

.inner-fcc-page .top .filter-icon,
.inner-fcc-page .top .add-icon {
  width: 36px;
  height: 36px;
  mask-image: url("../../assets/icons/filter\ 01.svg");
  background-color: #036323;
  mask-position: center;
  mask-repeat: no-repeat;
  margin-left: 30px;
  cursor: pointer;
}

.inner-fcc-page .top .add-icon {
  mask-image: url("../../assets/icons/plus-circle.svg");
}

.inner-fcc-page .filter {
  width: 100%;
  padding: 0 20px;
  height: 0px;
  border-bottom: 2px solid #00000010;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  background-color: #fff;
}

.inner-fcc-page .filter input {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #00000060;
  padding: 0 20px;
}

.inner-fcc-page .filter select {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #00000060;
  padding: 0 20px;
}

.inner-fcc-page .filter button {
  width: 220px;
  height: 46px;
  background-color: #036323;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inner-fcc-page .filter button .icon {
  font-size: 24px;
  margin-right: 10px;
  color: #ffffff;
}

.inner-fcc-page .filter.active-filter {
  opacity: 1;
  visibility: visible;
  height: 100px;
}

.inner-fcc-page .table-wrap {
  width: 100%;
  overflow-x: auto;
}

.inner-fcc-page .table-wrap table {
  width: 100%;
}

.inner-fcc-page .table-wrap table .bg {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/menu-line-horizontal.svg");
  mask-repeat: no-repeat;
  background-color: #000000;
  cursor: pointer;
  margin: auto;
}

.inner-fcc-page .table-wrap table .bg-circle {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/reload.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #000000;
  margin: auto;
}

.inner-fcc-page .table-wrap table .bg-circle.bg-circle-editable {
  background-color: #d71b1b;
  cursor: pointer;
}

.inner-fcc-page .table-wrap table tr {
  border-bottom: 2px solid #00000010;
  transition: 0.3s;
}

.inner-fcc-page .table-wrap table tr a {
  color: #036323;
  text-decoration: none;
}

.inner-fcc-page .table-wrap table th {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inner-fcc-page .table-wrap table td {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inner-fcc-page .table-wrap table tbody tr:nth-child(2n + 2) {
  background-color: #f6f6f6;
}

.inner-fcc-page .table-wrap table tbody tr:hover {
  background-color: #f6f6f6;
}

.inner-fcc-page .bottom-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.inner-fcc-page .bottom-pagination button {
  width: 36px;
  height: 36px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #036323;
  color: #ffffff;
  border: none;
  font-size: 18px;
  border-radius: 50%;
  position: relative;
}

.inner-fcc-page .bottom-pagination button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  margin: 0;
}

.inner-fcc-page .bottom-pagination button.button-disactive {
  opacity: 0.6;
  cursor: not-allowed;
}

.ant-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 50%;
  font-family: "Montserrat";
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #036323;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #036323;
  background-color: #036323;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #fff;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

.empty-table .ant-empty-description span {
  font-family: "Montserrat";
}

@media (max-width: 1600px) {
  .inner-fcc-page .filter input {
    width: 300px;
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter select {
    width: 300px;
    height: 40px;
    font-size: 16px;
    line-height: 100%;
    padding: 0 10px;
  }
  .inner-fcc-page .filter button {
    width: 180px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .inner-fcc-page .filter button .icon {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .inner-fcc-page .filter input {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter select {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter button {
    width: 150px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .inner-fcc-page .filter button .icon {
    font-size: 18px;
  }
  .inner-fcc-page .top .input-wrap {
    width: 360px;
    height: 40px;
  }
  .inner-fcc-page .top .input-wrap > div {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 14px;
  }
  .inner-fcc-page .top .input-wrap input {
    font-size: 14px;
    padding-left: 40px;
    outline: none;
  }
}

@media (max-width: 1200px) {
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
  }
  .inner-fcc-page .top .input-wrap {
    width: 220px;
    height: 40px;
  }
  .inner-fcc-page .top .filter-icon,
  .add-icon {
    width: 36px;
    height: 36px;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .inner-fcc-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .inner-fcc-page .top .right {
    width: 100%;
    justify-content: space-between;
  }
  .inner-fcc-page .filter {
    height: 0;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
  }
  .inner-fcc-page .filter.active-filter {
    height: auto;
  }
  .inner-fcc-page .filter input {
    width: 100%;
    margin: 10px 0;
  }
  .inner-fcc-page .filter select {
    width: 100%;
    margin: 10px 0;
  }
  .inner-fcc-page .filter button {
    width: 100%;
    margin: 10px 0;
  }

  .inner-fcc-page .top .input-wrap {
    width: 49%;
  }

  .inner-fcc-page .btn-wrap button {
    width: 49%;
    height: 36px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .inner-fcc-page .top .right {
    flex-direction: column;
    align-items: flex-end;
  }
  .inner-fcc-page .top .input-wrap {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
  .inner-fcc-page .top .filter-icon,
  .add-icon {
    margin-left: 10px;
  }
}
