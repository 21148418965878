.sidebar-component {
  position: fixed;
  top: 102px;
  left: -260px;
  width: 260px;
  height: calc(100% - 102px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  z-index: 100;
  overflow-y: auto;
}

.sidebar-component::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar-component.sidebar-component-active {
  position: fixed;
  top: 102px;
  left: 0;
  width: 260px;
  height: calc(100% - 102px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-component a {
  text-decoration: none;
}

.sidebar-component .top {
  padding-top: 46px;
}

.sidebar-component .top a .link {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
}

.sidebar-component .top a .link .down {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/down.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #111111;
  margin-left: auto;
  margin-right: 18px;
  transition: 0.3s;
}

.sidebar-component .top .divider {
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
  align-items: center;
}

.sidebar-component .top .divider:not(:nth-child(1)) {
  margin-top: 24px;
}

.sidebar-component .top .divider .d-line {
  width: 14px;
  height: 1px;
  /* border-top: 1px solid #b2b2b2; */
  background-color: #b2b2b2;
}

.sidebar-component .top .divider .d-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #b2b2b2;
  margin-left: 4px;
}
.sidebar-component .top a .link .bg {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/kr.svg");
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #111111;
  margin-right: 14px;
}

/* .sidebar-component .top a:nth-child(2) .bg {
  mask-image: url("../../assets/icons/kr.svg");
}

.sidebar-component .top a:nth-child(3) .bg {
  mask-image: url("../../assets/icons/akd.svg");
}

.sidebar-component .top a:nth-child(4) .bg {
  mask-image: url("../../assets/icons/cert.svg");
}

.sidebar-component .top a:nth-child(5) .bg {
  mask-image: url("../../assets/icons/article.svg");
}

.sidebar-component .top a:nth-child(7) .bg {
  mask-image: url("../../assets/icons/fum.svg");
}

.sidebar-component .top a:nth-child(6) {
  display: none;
}

.sidebar-component .top a:nth-child(6) .bg {
  mask-image: url("../../assets/icons/miner.svg");
}

.sidebar-component .top a:nth-child(8) .bg {
  mask-image: url("../../assets/icons/lab.svg");
} */

.sidebar-component .top a .link .bg {
  mask-image: url("../../assets/icons/certs.svg");
}
/* .sidebar-component .top a:nth-child(9) {
  display: none;
} */

/* .sidebar-component .top a:nth-child(11) .bg {
  mask-image: url("../../assets/icons/invoice.svg");
} */

.sidebar-component .top a:nth-child(4) .bg {
  mask-image: url("../../assets/icons/inst.svg");
}

/* .sidebar-component .top a:nth-child(12) .bg {
  mask-image: url("../../assets/icons/pay.svg");
} */

/* .sidebar-component .top a:nth-child(13) .bg {
  mask-image: url("../../assets/icons/compare.svg");
} */

/* .sidebar-component .top a:nth-child(13) .bg {
  mask-image: url("../../assets/icons/book-help.svg");
} */

.sidebar-component .top a:nth-child(5) .bg {
  mask-image: url("../../assets/icons/headphones.svg");
}

/* .sidebar-component .top a:nth-child(18) .bg {
  mask-image: url("../../assets/icons/agrohelp.svg");
} */

.sidebar-component .top a .link h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #111111;
  margin: 0;
}

.sidebar-component .top a .submenu {
  display: none;
}

.sidebar-component .top a .submenu.expanded {
  display: block;
}

.sidebar-component .top a .submenu a .link h1 {
  margin-left: 56px;
}

.sidebar-component .top a .submenu a .link.active-link {
  color: #036323;
  /* font-weight: 600; */
  background-color: #ffffff;
}

.sidebar-component .top a .submenu a .link.active-link > h1 {
  font-weight: 600;
}

.sidebar-component .top a .link .line {
  width: 8px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sidebar-component .top a .link.active-link {
  background-color: #00000005;
}

.sidebar-component .top a .link.active-link h1 {
  font-weight: 600;
}

.sidebar-component .top a .link.opened {
  background-color: #00000005;
}

.sidebar-component .top a .link .down.active {
  transform: rotate(180deg);
}

.sidebar-component .top a .link.active-link .line {
  background-color: #036323;
  opacity: 1;
  visibility: visible;
}

.sidebar-component .top a .link.opened .line {
  background-color: #036323;
  opacity: 1;
  visibility: visible;
}

.sidebar-component .top a .link.active-link .bg {
  background-color: #036323;
}

.sidebar-component .top a .link.active-link h1 {
  color: #036323;
}

.sidebar-component .top a .link:hover {
  background-color: #00000005;
}

.sidebar-component .top a .link:hover .line {
  opacity: 1;
  visibility: visible;
  background-color: #036323;
}

.sidebar-component .bottom .link {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.sidebar-component .bottom .link .bg {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/logout\ 01.svg");
  mask-position: center;
  background-color: #d71b1b;
  margin-right: 14px;
}

.sidebar-component .bottom .link h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #d71b1b;
  margin: 0;
}

.sidebar-component .bottom .link .line {
  width: 8px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sidebar-component .bottom .link:hover {
  background-color: #00000005;
}

.sidebar-component .bottom .link:hover .line {
  opacity: 1;
  visibility: visible;
  background-color: #d71b1b;
}

.sidebar-component .bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #cecece;
  padding: 20px;
  margin: 0;
}

.badge-new {
  position: absolute;
  /* top: 7px; */
  right: -5px;
  top: -5px;
}

@media (max-width: 1400px) {
  .sidebar-component .top a .link h1 {
    font-size: 14px;
  }
  .sidebar-component .top a .link .bg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    mask-size: cover;
  }
}

@media (max-width: 992px) {
  .sidebar-component {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

@media (max-width: 576px) {
  .sidebar-component {
    top: 122px;
  }
  .sidebar-component.sidebar-component-active {
    top: 122px;
  }
}
