.row {
  height: 100%;
}

.row .note {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.row .note .icon {
  font-size: 24px;
  color: #d71b1b;
  margin-right: 5px;
  margin-top: -2px;
}

.row .note p {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #d71b1b;
}

.cert-container h1 {
  font-size: 20px;
}
.cert-container h2 {
  font-size: 19px;
}
.cert-container h3 {
  font-size: 18px;
}

.cert-container {
  /* overflow-x: auto; */
  border-radius: 16px;
  padding: 10px 0;
  min-width: 1260px;
  background-color: #fff;
  font-family: "Sans serif";
  /* height: 100vh; */
  /* overflow-y: scroll;
  overflow: scroll; */
  /* font-size: 12px; */
}

.cert-container::-webkit-scrollbar {
  width: 10px !important;
  height: 50px !important;
}

.card-wrapper {
  height: 100vh;
  overflow-y: scroll;
}

.cert-container .card {
  min-width: 1240px;
  max-width: 1240px;
  /* min-height: 1754px; */
  /* height: 100%; */
  margin-left: auto;
  margin-right: auto;
  padding: 20px 140px;
  border-radius: 16px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  vertical-align: middle;
}

.printable-spot {
  background-image: url("../../assets/images/fss.svg");
  background-repeat: no-repeat;
  background-position: center 300px;
}
.printable-spot.replaced {
  background-image: url("../../assets/images/replaced2.svg");
  background-repeat: no-repeat;
  background-position: center 300px;
}

.cert-container table {
  width: 100%;
  margin-bottom: 20px;
}

.cert-container table.table-two td {
  width: 50%;
}

.cert-container table.table-three td {
  width: 33.33%;
}

.cert-container .btn-icon {
  width: 160px;
  height: 40px;
  border: none;
  /* background: #036323; */
  border-radius: 6px;
  background-color: #ffffff;
  color: #036323;
  background-image: url("../../assets/icons/printer.svg");
  background-repeat: no-repeat;
  background-position: 115px;
  background-size: 24px 24px;
  cursor: pointer;
  padding: 8px 25px;
  vertical-align: middle;
  font-size: 20px;
  text-align: left;
  line-height: 20px;
  border: 1px solid #036323;
}

.cert-container .btnWrap {
  position: relative;
}

.cert-container .iconWrap {
  position: absolute;
  top: 7px;
  left: 7px;
}

.cert-container .down-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cert-container .down-btn1 {
  text-align: right;
  position: relative;
}

.cert-container .tooltip {
  visibility: hidden;
  width: 120px;
  height: 28.5px;
  background-color: #036323;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  bottom: 0;
  top: 6px;
  right: 40px;
  z-index: 1;
}

.cert-container .btn-icon:hover + .tooltip {
  visibility: visible;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: 600;
}

.invoice-info table .border-none td,
.invoice-info table .border-none th {
  border: none;
}

.invoice-info table td,
.invoice-info table th {
  border: 1px solid #000;
  border-collapse: collapse;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  padding: 2px;
}

/* .invoice-info table.table-two > tbody > tr > td:first-child {
  border-left: none;
}

.invoice-info table.table-two > tbody > tr > td:last-child {
  border-right: none;
}
.invoice-info table.table-two > tbody > tr > th:first-child {
  border-left: none;
}

.invoice-info table.table-two > tbody > tr > th:last-child {
  border-right: none;
} */

.invoice-info table.product-table td,
.invoice-info table.product-table th {
  min-width: 12%;
  max-width: 13%;
}

.invoice-info table td {
  padding: 2px;
}

.nowrap {
  white-space: nowrap;
}

.main-act th {
  padding: 10px;
}
