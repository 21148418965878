.inner-fcc-page .cards-wrapper .invoice-card .card-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #0f9fd8 0%, #47f9f0 100%);
  padding: 24px;
  border-radius: 10px;
  /* z-index: 100; */
}

.inner-fcc-page .cards-wrapper .invoice-card .card-wrapper.card-red {
  background: linear-gradient(90deg, #c23a3aef 0%, #f9ac47 100%);
}

.inner-fcc-page .cards-wrapper a .card-wrapper {
  background: linear-gradient(90deg, #43b5e9 0%, #38f9d7 100%) !important;
}

@media (hover: hover) and (pointer: fine) {
  .inner-fcc-page .invoice-cards .cards-wrapper .card-wrapper:hover {
    filter: none;
  }

  .inner-fcc-page
    .invoice-cards
    .cards-wrapper
    .card-wrapper:hover
    + .on-hover-card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-content: center;
    pointer-events: none;
    z-index: 100;
  }
}

.inner-wrap-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inner-wrap-div .multi-select {
  width: 100%;
}

.inner-wrap-div .btn-advandced {
  border: none;
  width: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #00000010;
}

.filter-row {
  display: flex;
  margin-bottom: 20px;
}

.filter-row .ant-select {
  width: 200px;
  margin-right: 10px;
  margin-left: auto;
}

.filter-row .ant-input-group-wrapper {
  width: 200px;
  margin-right: 10px;
}
