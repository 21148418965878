@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap");
/* @font-face {
  font-family: "Montserrat";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap");
} */

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #f5f5f5;
  font-family: "Montserrat" !important;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* @page {
  margin: 30px;
} */

@media print {
  * {
    font-family: "Sans serif";
    /* -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;  */
    /* box-shadow: 0; */
  }
  /* @page {
    size: landscape;
  } */

  .th-heading {
    background-color: #c4c7c7 !important;
    print-color-adjust: exact;
  }

  .product-table th,
  .product-table td {
    width: 12%;
  }

  .invoice-info {
    font-family: "Sans serif";
  }

  .printable-spot {
    /* background-image: url("./assets/images/fss.svg") !important;
    background-repeat: no-repeat;
    background-position: center; */
    print-color-adjust: exact;
  }
  page {
    box-shadow: 0;
    margin: 0;
    margin-bottom: 0;
    /* padding: 15px; */
    box-shadow: none;
    font-family: serif;
    print-color-adjust: exact;
  }
}
